.tcards {
  padding: 0rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.tcards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
}

.tcards__containerProducts {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 820px;
  width: 90%;
  margin: 0 auto;
}

.tcards__wrapper {
  position: relative;
  margin: 0px 0 45px;
}

.tcards__items {
  margin-bottom: 24px;
}

.tcards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.tcards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.tcards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 55%;
  overflow: hidden;
}

.tcards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  background-color: #2e7fcf;
  box-sizing: border-box;
}

.tcards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.tcards__item__info {
  padding: 20px 30px 30px;
}

.tcards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .tcontent__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .tcards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .tcards {
    padding: 2rem;
    background: #fff;
  }

  .tcards__container,
  .tcards__containerProducts {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
  }

  .tcards__item {
    margin-bottom: 2rem;
  }

  .tcards__item__info {
    padding: 5px 8px 5px;
  }

  h1 {
    font-size: 25px;
  }
}
