.navbar {
  background: white;
  height: 110px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: black;
  font-weight: bold;
  padding-left: 10%;
  padding-right: 10%;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.logo {
  font-size: 2rem;
  color: black;
  font-weight: bold;
  height: 2.5rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 50px;
  padding-right: 8px;
}

.nav-links {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-linksActive {
  color: #2e7fcf;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid #2e7fcf;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.3s ease-out;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  height: 2;
}

.lanuguagedropdown {
  display: flex; /* Add this to make the dropdown appear in the flex layout */
  align-items: center; /* Align items vertically in the flex layout */
  font-size: 40px;
}

@media screen and (max-width: 1150px) {
  .NavbarItems {
    position: fixed;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 110px;
    left: -120%;
    opacity: 1;
    transition: all 0.25s ease;
  }

  .nav-menu.active {
    background: #2e7fcf;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links,
  .nav-linksActive {
    text-align: center;
    width: 100%;
    display: table;
    color: #fff;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 20%;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
    height: 1.5rem;
  }

  .lanuguagedropdown {
    position: absolute;
    right: 90px;
    top: 25px;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

@media screen and (max-width: 880px) {
  .lanuguagedropdown {
    position: absolute;
    right: 60px;
    top: 25px;
  }
}
