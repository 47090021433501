video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url("../images/img_b_resized.jpg") center center/cover no-repeat;
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container-solutions {
  background: url("../images/img_b_sol_resized.jpg") center center/cover
    no-repeat;
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container-products {
  background: url("../images/background02.jpg") center center/cover no-repeat;
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container-translabtor {
  background: url("../images/TransLabtorCard.png") center center/cover no-repeat;
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.translabtorfeatures-container {
  height: 35vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.translabtorfeatures {
  height: auto;
  width: auto;
}

.hero-container-aboutus {
  background: url("../images/img_aboutus.jpg") center center/cover no-repeat;
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container-contactus {
  background: url("../images/img_contactus_resized.jpeg") center center/cover
    no-repeat;
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1,
.hero-container-solutions > h1,
.hero-container-products > h1,
.hero-container-aboutus > h1,
.hero-container-contactus > h1 {
  color: #fff;
  font-size: 80px;
  margin-top: -70px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.hero-container > p,
.hero-container-solutions > p,
.hero-container-products > p,
.hero-container-aboutus > p,
.hero-container-contactus > p {
  margin-top: 18px;
  color: #fff;
  font-size: 48px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container,
  .hero-container-solutions,
  .hero-container-products,
  .hero-container-aboutus,
  .hero-container-contactus {
    height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .hero-container-translabtor {
    height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }

  .translabtorfeatures-container {
    height: 15vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }

  .translabtorfeatures {
    height: auto;
    width: 450px;
  }

  .hero-container > h1,
  .hero-container-solutions > h1,
  .hero-container-products > h1,
  .hero-container-aboutus > h1,
  .hero-container-contactus > h1 {
    font-size: 35px;
    margin-top: -20px;
  }

  .hero-container > p,
  .hero-container-solutions > p,
  .hero-container-products > p,
  .hero-container-aboutus > p,
  .hero-container-contactus > p {
    font-size: 20px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
