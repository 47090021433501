/* Style for the content container */
.content-container {
  margin: 0 auto; /* Center the container horizontally */
  max-width: 960px; /* Set the maximum width of the container */
  padding: 20px; /* Add padding to create space on the left and right */
  background-color: #fff; /* Add a background color if desired */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for a card-like appearance */
}

/* Define a container for each section */
.about-section,
.vision-section,
.our-team-section,
.contactus-section {
  background-color: #f7f7f7; /* Background color */
  padding: 20px; /* Add padding for spacing */
  margin-bottom: 20px; /* Add margin for separation */
}

/* Style for section headings (h2) */
h2 {
  color: #333; /* Heading text color */
  font-size: 24px; /* Font size */
  margin-bottom: 10px; /* Spacing below heading */
}

/* Style for the section container */
.why-choose-section,
.our-services-section {
  background-color: #f7f7f7; /* Background color */
  padding: 20px; /* Add padding for spacing */
  margin-bottom: 20px; /* Add margin for separation */
}

/* Style for section headings (h2) */
.why-choose-section h2,
.our-services-section h2 {
  color: #333; /* Heading text color */
  font-size: 24px; /* Font size */
  margin-bottom: 10px; /* Spacing below heading */
}

/* Style for unordered list (ul) */
.why-choose-section ul,
.our-services-section ul {
  list-style-type: disc; /* Use bullet points for list items */
  padding-left: 20px; /* Indent list items */
}

/* Style for list items (li) */
.why-choose-section li,
.our-services-section li {
  color: #666; /* Text color */
  font-size: 16px; /* Font size */
  line-height: 1.5; /* Line height for readability */
  margin-bottom: 10px; /* Spacing between list items */
}

/* Style for strong text within list items (for key points) */
.why-choose-section li strong,
.our-services-section li strong {
  font-weight: bold; /* Make key points bold */
  color: #333; /* Key point text color */
}
