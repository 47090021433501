/* Center the form vertically */
.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Center the form vertically */
.contact-form-container-contactus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Style form elements */
.form-group {
  width: 100%;
  margin-bottom: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  background-color: #fff;
}

* {
  box-sizing: border-box;
}

form {
  display: block;
  background-color: #fff;
  box-shadow: 1px 1px 3px 2px #ddd;
  margin: 40px auto;
  max-width: 600px;
  max-height: 700px;
  padding: 20px 30px;
}

form input,
select,
textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
}

form button {
  height: 25px;
  width: 60px;
  align-items: center;
  border: none;
  background-color: #2e7fcf;
  color: #fff;
  cursor: pointer;
}
