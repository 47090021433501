img {
  height: 24px;
}

.language-dropdown {
  position: relative;
  display: inline-block;

  .select {
    background-color: #fff;
    border: 1px solid #fff;
    padding: 3px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: fit-content;

    img {
      width: 20;
      margin-right: 5px;
    }

    .arrow {
      margin-left: auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 4px 0;
      border-color: #000 transparent transparent transparent;
      transition: transform 0.2s;
      transform-origin: 50%;
    }

    &.open .arrow {
      transform: rotate(180deg);
    }
  }

  .language-options {
    position: absolute;
    font-size: 20px;
    width: 150px;
    top: 100%;
    left: 10;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      transition: background-color 1s;
      &:hover {
        background-color: #2257b9;
      }

      img {
        margin-right: 15px;
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .language-options {
    position: absolute;
    font-size: 12px;
    width: 90px;
    top: 100%;
    left: 10;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      transition: background-color 1s;
      &:hover {
        background-color: #2257b9;
      }

      img {
        margin-right: 15px;
      }
    }
  }
}
